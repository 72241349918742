import type { PresentationBulkDateUpdate, SessionRead, SessionWrite } from "@api";
import { stringISOToDateTimeNullable } from "@mykey4/core";
import { type DateTime, Duration } from 'luxon';
import type { PresentationEditSchedule, SessionEditSchedule } from "../index";

export const sessionEditScheduleToSessionWrite = (src: SessionEditSchedule, dest: SessionRead, tagIds: Array<string>): SessionWrite => ({
	...dest,
	roomId: dest.room?.id,
	tagIds,
	startDate: src.newDateTimeStart?.toISO() ?? undefined,
	endDate: src.newDateTimeEnd && src.newDateTimeStart ? (setDateFromDateTime(src.newDateTimeEnd, src.newDateTimeStart).toISO() ?? undefined) : undefined,
	order: dest.order?.toString(),
});

const setDateFromDateTime = (target: DateTime, source: DateTime): DateTime => {
	return target.set({ year: source.year, month: source.month, day: source.day });
};

const buildFromDateOnlyAndTimeOnly = (datePart: DateTime, timePart: DateTime | undefined | null) => {
	return timePart ? datePart.toUTC().startOf("day").plus(Duration.fromObject({ hours: timePart.hour, minutes: timePart.minute })).toUTC().toISO() ?? undefined : undefined;
};

export const presentationEditScheduleToPresentationBulkDateUpdate = (
	src: Array<PresentationEditSchedule>,
	newStartDate: DateTime<true>,
): Array<PresentationBulkDateUpdate> =>
	src.map((presentation) => ({
		id: presentation.id,
		startDate: buildFromDateOnlyAndTimeOnly(newStartDate, presentation.newDateTimeStart),
		endDate: buildFromDateOnlyAndTimeOnly(newStartDate, presentation.newDateTimeEnd),
	}));

export interface SessionToEditScheduleArgs {
	sessionId: string;
	oldSession: SessionRead | SessionWrite | undefined | null;
	newSession: SessionRead | SessionWrite | undefined | null;
}

const areDatesEqual = (date1: DateTime | null, date2: DateTime | null): boolean => {
	if (!date1 && !date2) {
		return true;
	}
	if (!date1 || !date2) {
		return false;
	}
	return date1.equals(date2);
};

export const sessionsToEditSchedule = (args: SessionToEditScheduleArgs): SessionEditSchedule => {
	const { sessionId, oldSession, newSession } = args;
	const oldStart = stringISOToDateTimeNullable(oldSession?.startDate);
	const oldEnd = stringISOToDateTimeNullable(oldSession?.endDate);
	const newStart = stringISOToDateTimeNullable(newSession?.startDate);
	const newEnd = stringISOToDateTimeNullable(newSession?.endDate);

	const startDateChanged = !areDatesEqual(oldStart, newStart);
	const endDateChanged = !areDatesEqual(oldEnd, newEnd);

	return {
		id: sessionId,
		hasBeenUpdated: startDateChanged || endDateChanged,
		oldDateTimeStart: oldStart,
		oldDateTimeEnd: oldEnd,
		newDateTimeStart: newStart,
		newDateTimeEnd: newEnd,
	};
};
